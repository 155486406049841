import axios, { AxiosResponse } from "axios";
import { GetServerSidePropsContext } from "next";
import { setupHeadersOld } from "lib/http/setupHeaders";
import { call } from "./call-old";
import { ServiceEndpoints } from "./constants";
import { MethodType, ResponseStatus } from "./types";

export interface APIFail {
  status: ResponseStatus.ERROR;
  error: string; // any?
}

export interface APISuccess<T> {
  status: ResponseStatus.SUCCESS;
  body: T;
}

// T being the type of your expected successful response data
export type APIResponseUnion<T> = APIFail | APISuccess<T>;
export type APIResponse<T> = Promise<APIResponseUnion<T>>;

/**
 * SETUP the core SWR api request
 * @param url
 */
export async function orgFetcher(url: string): Promise<AxiosResponse<any>> {
  return getOrgClient()
    .get(url)
    .then((res) => res.data);
}

export async function ssrFetcher(url: string) {
  try {
    const res = await call(url, MethodType.GET, null, ServiceEndpoints.JOBS);
    if (res.ok) {
      const resObject = await res.json();
      return {
        data: resObject,
        headers: res.headers,
      };
    } else {
      throw new Error("Error getting jobs");
    }
  } catch (e) {
    return e;
  }
}

export async function jobsFetcher(url: string): Promise<AxiosResponse<any>> {
  try {
    return await getJobsClient()
      .get(url)
      .then((res) => {
        return {
          ...res,
          data: res.data,
          headers: res.headers,
        };
      })
      .catch((e) => {
        console.warn("inner e: ", e);
        throw new Error(e);
      });
  } catch (e: any) {
    throw new Error(`${e?.message ?? e}`);
  }
}

export async function seaTimeFetcher(url: string): Promise<AxiosResponse<any>> {
  return getSeaTimeClient()
    .get(url)
    .then((res) => res.data);
}

export async function marinerFetcher(url: string): Promise<AxiosResponse<any>> {
  return getMarinerClient()
    .get(url)
    .then((res) => res.data);
}

export async function authFetcher(url: string): Promise<AxiosResponse<any>> {
  return getAuthClient()
    .get(url)
    .then((res) => res.data);
}

export async function applicantFetcher(
  url: string
): Promise<AxiosResponse<any>> {
  return getApplicantsClient()
    .get(url)
    .then((res) => res.data);
}

export function getOrgClient(ctx?: GetServerSidePropsContext) {
  const headers = setupHeadersOld({ ctx });
  return axios.create({
    baseURL: ServiceEndpoints.ORG,
    headers,
  });
}

export function getJobsClient(ctx?: GetServerSidePropsContext) {
  try {
    const headers = setupHeadersOld({ ctx });
    return axios.create({
      baseURL: ServiceEndpoints.JOBS,
      headers,
    });
  } catch (e) {
    throw new Error("Error in getJobsClient");
  }
}

export function getSeaTimeClient(ctx?: GetServerSidePropsContext) {
  const headers = setupHeadersOld({ ctx });
  return axios.create({
    baseURL: ServiceEndpoints.SEATIME,
    headers,
  });
}

export function getMarinerClient(ctx?: GetServerSidePropsContext) {
  const headers = setupHeadersOld({ ctx });
  return axios.create({
    baseURL: ServiceEndpoints.MARINER,
    headers,
  });
}

export function getAuthClient(
  ctx?: GetServerSidePropsContext,
  unAuthOnly?: boolean
) {
  const headers = setupHeadersOld({ contentType: undefined, ctx, unAuthOnly });
  return axios.create({
    baseURL: ServiceEndpoints.AUTH,
    headers,
  });
}

export function getDeeplinkClient(
  ctx?: GetServerSidePropsContext,
  unAuthOnly?: boolean
) {
  const headers = setupHeadersOld({ contentType: undefined, ctx, unAuthOnly });
  return axios.create({
    baseURL: ServiceEndpoints.DEEPLINK,
    headers,
  });
}

export function getApplicantsClient(ctx?: GetServerSidePropsContext) {
  const headers = setupHeadersOld({ ctx });
  return axios.create({
    baseURL: ServiceEndpoints.APPLICANTS,
    headers,
  });
}

// NEWS
export async function newsFetcher(url: string): Promise<AxiosResponse<any>> {
  return getNewsClient()
    .get(url)
    .then((res) => {
      return {
        ...res,
        data: res.data,
        headers: res.headers,
      };
    });
}

export function getNewsClient(ctx?: GetServerSidePropsContext) {
  const headers = setupHeadersOld({ ctx });
  return axios.create({
    baseURL: ServiceEndpoints.NEWS,
    headers,
  });
}
